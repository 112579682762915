import { IsNumber, IsPositive } from 'class-validator';

export class CreateCloverPaymentRequest {
  /**
   * Order related to this payment.
   *
   * @type {number}
   * @memberof CreateCloverPaymentRequest
   */
  @IsNumber()
  @IsPositive()
  orderId: number;
}
