import { Type } from 'class-transformer';
import { IsDate, IsBoolean, IsOptional, ValidateNested, IsString, IsNumber } from 'class-validator';

import { OrderItemBaseObject } from '../objects/order-item-base.object';

/**
 * Update an order.
 */
export class UpdateOrderRequest {
  /**
   * Items in the order. Could be nested.
   *
   * @type {OrderItemBaseObject[]}
   * @memberof CreateOrderRequest
   */
  @Type(() => OrderItemBaseObject)
  @ValidateNested()
  @IsOptional()
  items?: OrderItemBaseObject[];

  /**
   * Date of retrieval for the order.
   *
   * @type {Date}
   * @memberof CreateOrderRequest
   */
  @Type(() => Date)
  @IsDate()
  @IsOptional()
  retrievalDate?: Date;

  /**
   *
   *
   * @type {Boolean}
   */
  @Type(() => Boolean)
  @IsBoolean()
  @IsOptional()
  injectNow?: boolean;

  /**
   *
   *
   * @type {String}
   */
  @Type(() => String)
  @IsString()
  @IsOptional()
  sessionId?: string;

  /**
   *
   *
   * @type {Boolean}
   */
  @Type(() => Boolean)
  @IsBoolean()
  @IsOptional()
  isOnMyWay?: boolean;
  /**
   * the estimated time of arrival (in second) of the user on MOP
   *
   * @type {number}
   * @memberof OrderObject
   */
  @IsOptional()
  @IsNumber()
  estimatedTimeOfArrival?: number;
  /**
   * Next interval check (in second) for user arrival on MOP
   *
   * @type {number}
   * @memberof OrderObject
   */
  @IsOptional()
  @IsNumber()
  nextETACheck?: number;
  /**
   * user's pickup mode
   *
   * @type {string}
   * @memberof OrderObject
   */
  @IsOptional()
  @IsString()
  pickUpMode?: string;
}
