import {
  CloverPaymentObject,
  CreateCloverPaymentRequest,
  CreateFakePaymentRequest,
  CreateNeptingPaymentRequest,
  CreateStripePaymentRequest,
  NeptingPaymentMethodObject,
  NeptingPaymentObject,
  StripePaymentObject,
} from '@acrelec-cloud/apico-shared';
import axios, { AxiosInstance } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { ApicoSDK } from '../../apico-sdk';

export class PaymentsService {
  protected readonly axiosInstance: AxiosInstance;

  constructor(protected readonly sdk: ApicoSDK) {
    this.axiosInstance = axios.create({
      baseURL: sdk.getEndpoint(),
      withCredentials: true,
    });
    axiosBetterStacktrace(this.axiosInstance);
  }

  async createStripePayment(
    createStripePaymentRequest: CreateStripePaymentRequest,
  ): Promise<StripePaymentObject> {
    return this.axiosInstance
      .post(`/payments/stripe`, createStripePaymentRequest)
      .then(response => response.data);
  }

  async createFakePayment(
    createFakePaymentRequest: CreateFakePaymentRequest,
  ): Promise<StripePaymentObject> {
    return this.axiosInstance
      .post(`/payments/fake`, createFakePaymentRequest)
      .then(response => response.data);
  }

  async createCloverPayment(
    createCloverPaymentRequest: CreateCloverPaymentRequest,
  ): Promise<CloverPaymentObject> {
    return this.axiosInstance
      .post(`/payments/clover`, createCloverPaymentRequest)
      .then(response => response.data);
  }

  async createNeptingPayment(
    createNeptingPaymentRequest: CreateNeptingPaymentRequest,
  ): Promise<NeptingPaymentObject> {
    return this.axiosInstance
      .post(`/payments/nepting`, createNeptingPaymentRequest)
      .then(response => response.data);
  }

  async findNeptingPaymentMethods(): Promise<NeptingPaymentMethodObject[]> {
    return this.axiosInstance.get(`/payment-methods/nepting`).then(response => response.data);
  }

  async deleteNeptingPaymentMethod(paymentMethodId: number): Promise<void> {
    return this.axiosInstance
      .delete(`/payment-methods/nepting/${paymentMethodId}`)
      .then(response => response.data);
  }
}
