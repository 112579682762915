export enum TaxRoundingMode {
  UP = 'UP',
  DOWN = 'DOWN',
  CEILING = 'CEILING',
  FLOOR = 'FLOOR',
  HALF_DOWN = 'HALF_DOWN',
}

export class TaxObject {
  id: number;
  campaignId: number;
  calculationScale: number;
  isIncludedInPrice: boolean;
  basis?: number;
  description: string;
  rate: number;
  roundingMode: TaxRoundingMode;
}
